/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
} from 'antd';
import {
  PageSection,
  messageError,
  ComponentLoader,
  messageSuccess,
} from '@deltaohm/ant-components';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';
import { H2090_UPDATE_MUTATION } from '../queries';
import { H2090EnabledMonitor, H2090DisabledMonitor } from '../components';

const H2090GeneralTab = (props) => {
  const {
    h2090,
  } = props;

  const { t } = useTranslation();

  return (
    <PageSection
      title={t('h2090s.monitorTab.title')}
    >
      <Row>
        <Col xs={24}>
          {h2090.monitorEnabled
            ? <H2090EnabledMonitor h2090={h2090} />
            : <H2090DisabledMonitor h2090={h2090} />}
        </Col>
      </Row>
    </PageSection>
  );
};

const propTypes = {
  h2090: PropTypes.object.isRequired,
};

H2090GeneralTab.propTypes = propTypes;

export default H2090GeneralTab;
