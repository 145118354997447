import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Empty,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
  messageError,
  ComponentLoader,
} from '@deltaohm/ant-components';

import { extractGraphQLErrors } from '../../utils/graphql';

import { ErrorList } from '../../generics';
import { H2090_ENABLE_MONITOR_MUTATION } from '../queries';

const H2090DisabledMonitor = (props) => {
  const {
    h2090,
  } = props;

  const { t } = useTranslation();

  const [
    h2090EnableMonitorMutationAction,
    {
      loading: loadingUpdate,
      error: errorUpdate,
    },
  ] = useMutation(H2090_ENABLE_MONITOR_MUTATION);

  React.useEffect(() => {
    if (errorUpdate) {
      const errors = extractGraphQLErrors(errorUpdate, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [errorUpdate, t]);

  return (
    <Row>
      <Col span={24}>
        <Empty
          description={t('h2090s.components.disabledMonitor.disableDescription')}
        >
          <Button
            type="primary"
            loading={loadingUpdate}
            onClick={() => h2090EnableMonitorMutationAction({
              variables: {
                input: {
                  id: h2090.id,
                  enable: true,
                },
              },
            })}
            block
          >
            {t('h2090s.components.disabledMonitor.enableButton')}
          </Button>
        </Empty>
      </Col>
    </Row>
  );
};

const propTypes = {
  h2090: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

H2090DisabledMonitor.propTypes = propTypes;

export default H2090DisabledMonitor;
