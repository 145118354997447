import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Empty,
  List,
  Button,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSubscription, useMutation } from '@apollo/client';
import {
  messageError,
} from '@deltaohm/ant-components';

import { extractGraphQLErrors } from '../../utils/graphql';
import { ErrorList } from '../../generics';
import { H2090_ENABLE_MONITOR_MUTATION, H2090_MONITOR_DATA_SUBSCRIPTION } from '../queries';

const H2090EnabledMonitor = (props) => {
  const {
    h2090,
  } = props;

  const { t } = useTranslation();
  const [data, setData] = React.useState([]);

  const [h2090EnableMonitorMutationAction,
    {
      loading: loadingUpdate,
      error: errorUpdate,
    },
  ] = useMutation(H2090_ENABLE_MONITOR_MUTATION);

  const {
    error: errorSubscription,
  } = useSubscription(H2090_MONITOR_DATA_SUBSCRIPTION, {
    variables: {
      id: h2090.id,
    },
    onSubscriptionData: ({ subscriptionData }) => {
      setData((oldData) => [...oldData, subscriptionData.data.h2090MonitorData]);
    },
  });

  React.useEffect(() => {
    if (errorSubscription || errorUpdate) {
      const errors = extractGraphQLErrors(errorSubscription || errorUpdate, t);
      messageError({
        content: <ErrorList errors={errors} />,
      });
    }
  }, [errorSubscription, errorUpdate, t]);

  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Button
              type="primary"
              loading={loadingUpdate}
              onClick={() => h2090EnableMonitorMutationAction({
                variables: {
                  input: {
                    id: h2090.id,
                    enable: false,
                  },
                },
              })}
              block
            >
              {t('h2090s.components.enabledMonitor.disableButton')}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <List>
              {data.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <List.Item key={index}>
                  {item}
                </List.Item>
              ))}
            </List>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

const propTypes = {
  h2090: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

H2090EnabledMonitor.propTypes = propTypes;

export default H2090EnabledMonitor;
