import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
} from '@apollo/client';

import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';

import config from './config';

const devicesWSLink = new GraphQLWsLink(
  createClient({
    url: config.graphQLUri.replace('https', 'ws').replace('http', 'ws'),
    retryAttempts: 5,
    connectionAckWaitTimeout: 2000,
    retryWait: async function waitForServerHealthyBeforeRetry(retryAttemps) {
      if (retryAttemps >= 4) {
        console.debug(`socket:DISCONNECTED ${new Date()}`);
      }
      await new Promise((resolve) => {
        setTimeout(resolve, 5000);
      });
    },
    lazy: true,
    keepAlive: 1000,
    connectionParams: async () => ({
      token: window.localStorage.getItem('token'),
    }),
    on: {
      error: (errors) => {
        console.log(errors);
        console.error(`ERROR: ${JSON.stringify(errors)}`);
      },
      connected: () => {
        console.log(`socket:CONNECTED ${new Date()}`);
      },
      closed: (reason) => {
        console.log(`socket:CLOSED ${new Date()}`);
      },
      connecting: () => {
        console.log(`socket:CONNECTING ${new Date()}`);
      },
      ping: async () => {},
      pong: async () => {},
    },
  }),
);

const authLink = setContext((_, { headers }) => {
  const token = window.localStorage.getItem('token');
  const authHeaders = {
    authorization: token ? `Bearer ${token}` : '',
  };
  return {
    headers: {
      ...headers,
      ...authHeaders,
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    window.localStorage.removeItem('token');
    window.location.replace('/');
  }
  else if (graphQLErrors) {
    // eslint-disable-next-line no-console
    graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));
  }
});

const devicesHttpLink = createHttpLink({ uri: config.graphQLUri });

const deviceSplitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    );
  },
  devicesWSLink,
  devicesHttpLink,
);

const devicesHttpCompositeLink = ApolloLink.from([
  errorLink,
  authLink,
  deviceSplitLink,
]);

export const devicesApolloClient = new ApolloClient({
  link: devicesHttpCompositeLink,
  cache: new InMemoryCache({
    possibleTypes: {
      Node: ['ComboStation', 'H2090'],
      Device: ['ComboStation', 'H2090'],
    },
  }),
});

const noiseStudioHttpLink = createUploadLink({ uri: config.noiseStudioGraphQLUri });

const noiseStudioHttpCompositeLink = ApolloLink.from([
  errorLink,
  authLink,
  noiseStudioHttpLink,
]);

export const noiseStudioApolloClient = new ApolloClient({
  link: noiseStudioHttpCompositeLink,
  cache: new InMemoryCache({
    possibleTypes: {
      Node: ['Worspace'],
    },
  }),
});
