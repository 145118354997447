export default {
  it: {
    translations: {
      common: {
        loading: 'Caricamento',
        loadMore: 'Carica ancora',
        never: 'Mai',
        filter: 'Filtra',
        actions: 'Azioni',
        successMessage: 'Operazione eseguita con successo',
        edit: 'Modifica',
        delete: 'Elimina',
        save: 'Salva',
        update: 'Aggiorna',
        download: 'Download',
        upload: 'Upload',
        detail: 'Dettagli',
        add: 'Aggiungi',
        yes: 'Si',
        no: 'No',
        sureToDelete: 'Sicuro di eliminare?',
        create: 'Crea',
      },
      validations: {
        required: 'Richiesto',
        fromTo: 'L\'inizio deve essere minore della fine',
        notInList: 'Valore non incluso in {{ items }}',
      },
      enums: {
        soundMeters: {
          inputGains: {
            GAIN_0dB: '0 dB',
            GAIN_10dB: '10 dB',
            GAIN_20dB: '20 dB',
            GAIN_30dB: '30 dB',
            GAIN_40dB: '40 dB',
          },
          noiseOrigins: {
            FF: 'FF',
            RI: 'RI',
          },
          windscreenCorrections: {
            OFF: 'OFF',
            SAV: 'SAV',
            WME: 'WME',
          },
          ponds: {
            A: 'A',
            C: 'C',
            Z: 'Z',
          },
          parameters: {
            LPK: 'Lpk',
            LPKMX: 'Lpkmx',
            LEQS: 'LeqS',
            LEQ: 'Leq',
            LFP: 'LFp',
            LSP: 'LSp',
            LIP: 'LIp',
            LFMX: 'LFmx',
            LSMX: 'LSmx',
            LIMX: 'LImx',
            LFMN: 'LFmn',
            LSMN: 'LSmn',
            LIMN: 'LImn',
            LE: 'LE',
            LEQI: 'LeqI',
            DOSE: 'DOSE',
            DOSE_D: 'DOSE D',
            LAV4: 'LAV4',
            LAV5: 'LAV5',
            LEP_D: 'LEP D',
            EA: 'EA',
            L1: 'L1',
            L2: 'L2',
            L3: 'L3',
            L4: 'L4',
            OL: 'OL',
            UR: 'UR',
          },
          spectreTypes: {
            AVERAGE: 'AVERAGE',
            MULTISPECTRE: 'MULTISPECTRE',
            MAXIMUM: 'MAXIMUM',
            MINIMUM: 'MINIMUM',
          },
          spectreMeans: {
            LINEAR: 'Lineare',
            EXPONENTIAL: 'Esponenziale',
          },
          spectreMeanWeights: {
            SLOW: 'Slow',
            FAST: 'Fast',
          },
          thirdOctaveMeans: {
            LINEAR: 'Lineare',
            EXPONENTIAL: 'Esponenziale',
          },
          thirdOctaveMeanWeights: {
            SLOW: 'Slow',
            FAST: 'Fast',
          },
          FFTBands: {
            HF: 'HF',
            MF: 'MF',
            LF: 'LF',
          },
          times: {
            TIME_0_125s: '0.125s',
            TIME_0_25s: '0.25s',
            TIME_0_5s: '0.5s',
            TIME_1s: '1s',
            TIME_2s: '2s',
            TIME_5s: '5s',
            TIME_10s: '10s',
            TIME_20s: '20s',
            TIME_30s: '30s',
            TIME_1m: '1m',
            TIME_2m: '2m',
            TIME_5m: '5m',
            TIME_10m: '10m',
            TIME_20m: '20m',
            TIME_30m: '30m',
            TIME_1h: '1h',
          },
        },
      },
      sideMenu: {
        devices: 'Dispositivi remoti',
        soundMeterHD2Series: 'Fonometri locali',
      },
      rightMenu: {
        editProfile: 'Modifica profilo',
        logout: 'Esci',
      },
      extractGraphQLErrors: {
        networkError: 'Server non raggiungibile, controllare connessione',
        unexpectedError: 'Si è verificato un errore imprevisto',
      },
      breadcrumbs: {
        devices: 'Dispositivi remoti',
      },
      devices: {
        list: {
          title: 'Dispositivi remoti',
          subtitle: 'Lista dei dispositivi remoti',
          table: {
            serialNumber: 'Numero Seriale',
            typename: 'Tipo',
            name: 'Nome',
            lastOnline: 'Ultima connessione',
            nextOnline: 'Prossima connessione',
            active: 'Attivo',
            inMaintenance: 'In manutenzione',
            activate: 'Attiva',
            deactivate: 'Disattiva',
            maintenanceOn: 'Attiva manutenzione',
            maintenanceOff: 'Disattiva manutenzione',
          },
        },
        components: {
          filter: {
            search: 'Ricerca',
            active: 'Attivo',
          },
        },
      },
      h2090s: {
        components: {
          form: {
            name: 'Nome',
          },
          updateWorkspaceForm: {
            workspaceId: 'Workspace',
          },
          changeUserForm: {
            email: 'Email',
          },
          disabledMonitor: {
            disableDescription: 'Il monitor è disabilitato',
            enableButton: 'Abilita monitor',
          },
          enabledMonitor: {
            disableButton: 'Disabilita monitor',
          },
        },
        detail: {
          tabs: {
            general: 'Generale',
            updateWorkspace: 'Aggiorna workspace',
            user: 'Utenti',
            monitor: 'Monitor',
          },
        },
        updateWorkspaceTab: {
          title: 'Aggiorna workspace',
        },
        monitorTab: {
          title: 'Monitor',
        },
      },
      comboStations: {
        components: {
          form: {
            name: 'Nome',
            timezone: 'Fuso orario',
          },
          changeUserForm: {
            email: 'Email',
          },
          currentConfigForm: {
            configId: 'Configurazione corrente',
            workspaceId: 'Workspace',
          },
          configCreateModal: {
            title: 'Creazione nuova configurazione',
          },
          configEditModal: {
            title: 'Modifica configurazione',
          },
          configForm: {
            general: {
              title: 'Generale e programmazione',
              name: 'Nome',
              sleepTime: 'Minuti di riposo',
              uploadCycle: 'Cicli per l\'upload',
              scheduleDivider: 'Programmazione',
              startDate: 'Inizio',
              endDate: 'Fine',
              continuous: 'Registrazione continua',
              mondayTitle: 'Lunedì',
              tuesdayTitle: 'Martedì',
              wednesdayTitle: 'Mercoledì',
              thursdayTitle: 'Giovedì',
              fridayTitle: 'Venerdì',
              saturdayTitle: 'Sabato',
              sundayTitle: 'Domenica',
              addSchedule: 'Aggiungi programmazione',
            },
            soundMeter: {
              title: 'Configurazione fonometro',
              inputGain: 'Input gain',
              noiseOrigin: 'Noise origin',
              l1: 'L1',
              l2: 'L2',
              l3: 'L3',
              l4: 'L4',
              timeHistoryDivider: 'Time History',
              timeHistoryActive: 'Time history attivo',
              timeHistoryPond: 'Ponderazione profile',
              timeHistorySlmParameter: 'Parametro {{index}}',
              timeHistorySlmPond: 'Ponderazione parametro {{index}}',
              timeHistoryThirdOctaveActive: 'Terzi d\'ottava attivi',
              timeHistoryThirdOctaveMean: 'Media',
              timeHistoryThirdOctaveMeanWeight: 'Peso media',
              reportDivider: 'Report',
              reportActive: 'Report attivo',
              reportInterval: 'Intervallo',
              reportParameter: 'Parametro {{index}}',
              reportPond: 'Ponderazione parametro {{index}}',
              reportThirdOctave: 'Terzi d\'ottava attivi',
              reportStatistics: 'Statistiche',
            },
          },
        },
        detail: {
          tabs: {
            general: 'Generale',
            currentConfig: 'Configurazione corrente',
            configs: 'Configurazioni',
            user: 'Utenti',
          },
        },
        generalTab: {
          title: 'Generale',
          lastMonitor: 'Ultimo monitor {{date}}',
          lastStatus: 'Ultimo status {{date}}',
        },
        currentConfigTab: {
          title: 'Configurazione corrente',
        },
        userTab: {
          title: 'Utenti',
          userTitle: 'Utilizzatore',
          ownersTitle: 'Proprietari',
        },
        configsTab: {
          title: 'Configurazioni',
          table: {
            name: 'Nome',
          },
        },
      },
      soundMeterHD2Series: {
        components: {
          configCreateModal: {
            title: 'Creazione nuova configurazione',
          },
          configEditModal: {
            title: 'Modifica configurazione',
          },
          setConfigButton: {
            sendConfigError: 'Errore durante il trasferimento della configurazione',
            modalTitle: 'Invio configurazione',
          },
          configForm: {
            title: 'Configurazione fonometro',
            name: 'Nome',
            inputGain: 'Input gain',
            noiseOrigin: 'Noise origin',
            windscreenCorrection: 'Windshield correction',
            overloadLevels: 'Overload levels',
            doseExchangeRate: 'Dose exchange rate',
            doseThreshold: 'Dose threshold',
            doseCriterion: 'Dose criterion',
            l1: 'L1',
            l2: 'L2',
            l3: 'L3',
            l4: 'L4',
            spectreDivider: 'Spettro',
            spectreTime: 'Tempo spettro',
            spectreType: 'Tipo spettro',
            spectreMean: 'Media spettro',
            spectreMeanWeight: 'Peso media spettro',
            timeHistoryDivider: 'Time History',
            timeHistoryActive: 'Time history attivo',
            timeHistoryProfileParameter: 'Parametro profile',
            timeHistoryProfilePond: 'Ponderazione profile',
            timeHistoryProfileTime: 'Tempo profilo',
            timeHistorySlmParameter: 'Parametro {{index}}',
            timeHistorySlmPond: 'Ponderazione parametro {{index}}',
            timeHistoryOctave: 'Ottave',
            timeHistoryThirdOctave: 'Terzi d\'ottava',
            leqShort: 'LEQ short',
            FFTActive: 'FFT attivo',
            FFTBand: 'Banda FFT',
            reportDivider: 'Report',
            reportActive: 'Report attivo',
            reportInterval: 'Intervallo',
            reportParameter: 'Parametro {{index}}',
            reportPond: 'Ponderazione parametro {{index}}',
            reportOctave: 'Ottave',
            reportThirdOctave: 'Terzi d\'ottava',
            reportStatistics: 'Statistiche attive',
            reportStatisticsParamenter: 'Parametro statistiche',
            reportStatisticsPond: 'Ponderazione statistiche',
          },
        },
        connect: {
          infoTitle: 'Connessione fonometri locali',
          infoSubTitle: 'Collegare il fonometro attraverso la porta USB ed utilizzare il pulsante "Connetti"',
          connectButton: 'Connetti',
        },
        manage: {
          title: 'Fonometri locali',
          subtitle: 'Gestione fonometri locali',
          serial: 'Comunicazione seriale',
          serialUnavailable: 'Il tuo browser non supporta la comunicazione seriale. Prova ad utilizzare Chrome o Edge',
          cannotConnect: {
            title: 'Impossibile comunicare con il dispositivo',
            link: 'Assicurarsi che sia correttamente collegato',
            port: 'Assicurarsi che la porta seriale selezionata sia corretta',
            model: 'Assicurarsi che il modello sia supportato',
            usb: 'Assicurarsi che sia abilitata la comunicazione USB',
          },
          tabs: {
            info: 'Info',
            configs: 'Configurazioni',
            download: 'Download',
          },
        },
        infoTab: {
          title: 'Info',
          serialNumberLabel: 'Seriale',
          modelNumberLabel: 'Modello',
          optionLabel: 'Opzione',
          classLabel: 'Classe',
        },
        configsTab: {
          title: 'Configurazioni',
          table: {
            name: 'Nome',
            setConfig: 'Invia configurazione',
          },
        },
        downloadTab: {
          title: 'Download',
          downloadFile: 'Download file',
          uploadNoiseStudio: 'Upload noise studio',
          fileName: 'Nome file',
          workspaceId: 'Workspace',
          timezone: 'Fuso orario',
          namePrefix: 'Prefisso del nome sessione',
          uploadSuccess: 'Upload effettuato correttamente',
          errors: {
            binAutoTimeoutOrInvalidMemoryFormat: 'Timeout nella richiesta del dump oppure formato memoria non corretto',
            noFilesFound: 'Nessun file trovato',
          },
        },
      },
    },
  },
};
